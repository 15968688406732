import { Box, IconButton, Snackbar, snackbarContentClasses } from "@mui/material"
import { Portal } from "modules/picasso-ui/specials/Portal"
import { ReactNode } from "react"
import { FunctionComponent } from "react"
import { useEffect, useState } from "react"
import { isHavingValue, isNullOrUndefined } from "utils/objectUtils"

const styles = {
    root: {
        borderRadius: '100px',
        display: 'flex',
        flexWrap: 'nowrap',
        //height: '40px',
        '& .rbar-unselected': {
            filter: 'grayscale(1)',
            opacity: '0.6',
        },
    },
    snackbar: {
        '& .rbar-emoji': {
            fontSize: '22px',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}

export interface ReactionsBarProps {
    selected?: string[]
    initialSelected?: string[]
    style?: any
    buttonProps?: any
    onChange?: (e: ChangeEvent) => void
    counts: Map<string, number>
    endContent?: ReactNode
}

export interface ChangeEvent {
    action: ChangeAction
    value: string
}

export enum ChangeAction {
    select = 'select',
    unselect = 'unselect',
}

export const ReactionsBar: FunctionComponent<ReactionsBarProps> = ({selected, initialSelected, style, buttonProps, onChange, counts, endContent}) => {

    const options = [
        /*         {
                    button: <span>&#128077;</span>,
                    value: '&#128077;',
                },
                {
                    button: <span>&#128078;</span>,
                    value: '&#128078;',
                }, */
                {
                    button: <span>&#128525;</span>,
                    value: 'emoji:&#128525;',
                    successMessage: <>You love this&nbsp;<span className="rbar-emoji">&#128525;</span></>,
                },
                {
                    button: <span>&#11088;</span>,
                    value: 'emoji:&#11088;',
                    successMessage: <>Marked as favorite&nbsp;<span className="rbar-emoji">&#11088;</span></>,
                },
            ]

    const [selectedEffective, setSelectedEffective] = useState<string[]>(selected || initialSelected || [])
 

    useEffect(()=>{
        if (isNullOrUndefined(selected)) {
            return;
        }
        if (selected !== selectedEffective) {
            setSelectedEffective(selected)
        }
    },[selected])

    const [changed, setChanged] = useState<any>()

    const handleClick = (r) => {
        setSelectedEffective(cur=>{
            if (cur.includes(r.value)) {
                return cur.filter(v=>r.value!==v)
            } else {
                return [...cur, r.value]
            }
        })

        const action = r.selected ? ChangeAction.select : ChangeAction.unselect

        if (action === ChangeAction.select) {
            // Trigger Snackbar
            setChanged({
                action,
                value: r.value,
                message: r.successMessage,
            })
        }

        onChange?.({
            action: action,
            value: r.value
        })
    }

    const handleSnackbarClose = () => {
        setChanged(null)
    }

    const reactionsToRender = options.map(r=>({
        ...r,
        selected: selectedEffective.includes(r.value)
    }))
    
    return (
        <>
            <Box sx={[styles.root, style]}>
                {reactionsToRender.map(r=>{
                    const totalCount = counts?.['total']
                    const reactionCount = counts
                     ? totalCount === 0 ? 0 : (counts?.[r.value] || 0)
                     : null

                    return (
                        <Box display="flex" alignItems="center" key={r.value} >
                            <IconButton color="inherit" {...buttonProps} className={r.selected? null : 'rbar-unselected'} onClick={()=>handleClick({...r, selected: r.selected !== true})}>
                                {r.button}
                            </IconButton>
                            {isHavingValue(reactionCount) && 
                                <span 
                                    //@ts-ignore
                                    css={theme=>({color: theme.palette.text.secondary})}>{reactionCount}
                                </span>
                            }
                        </Box>
                    )
                })}
                {endContent}
            </Box>
            <Portal>
                <Snackbar
                    key={changed?.value||null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={isHavingValue(changed)}
                    onClose={handleSnackbarClose}
                    message={<div css={styles.snackbar}>{changed?.message}</div>}
                    sx={{[`& .${snackbarContentClasses.message}`]: {width: '100%',}}}
                    autoHideDuration={2000}
                />
            </Portal>
        </>
        )

}