import * as backlogItemsApi from "modules/plinzip/api/backlogItemsApi"
import { useSWRConfig } from "swr"
import { entityId } from "utils/apiUtils"
import { useBacklogReactionStatsApi } from "../../rankings/results/useBacklogReactionStats"
import { getItemReactionsMyFetchKey } from "./useItemReactions"

export interface ItemReactionsApiProps {
    createReaction: (backlogId: string, itemId: string, value: string) => Promise<any>
    deleteReaction: (backlogId: string, itemId: string, reactionId: string, value: string) => Promise<any>
}

export const useItemReactionsApi = ():ItemReactionsApiProps => {

    const { mutate } = useSWRConfig()

    const backlogReactionStatsApi = useBacklogReactionStatsApi()

    const createReaction = (backlogId, itemId, value) => {
        const updateFn = () => backlogItemsApi.createReaction(backlogId, itemId, value)

        const tempId = 'tempId:'+entityId()

        const options = { 
            populateCache: (res, currentData) =>{
                const createdReaction = res.data
                let updated = currentData ? [...currentData] : []
                updated = updated.filter(r=>r.reactionId !== tempId)
                updated = updated.filter(r=>r.reactionId !== createdReaction.reactionId)
                updated.push(createdReaction)
                return updated;
            },
            revalidate: true,
            optimisticData: (currentData) => {
                const updated = currentData ? [...currentData] : []
                currentData.push({
                    reactionId: tempId,
                    _unsaved: true,
                    value: value,
                })
                return updated
            },
        }

        backlogReactionStatsApi.increaseOptimisicCount(backlogId, itemId, value)

        return mutate(getItemReactionsMyFetchKey(backlogId, itemId), updateFn, options)
    }

    const deleteReaction = (backlogId, itemId, reactionId, value) => {
        const updateFn = () => {
            if (reactionId.startsWith('tempId:')) {
                // nothing to do
                return Promise.resolve()
            } else {
                return backlogItemsApi.deleteReaction(backlogId, itemId, reactionId)
            }
        }

        const options = { 
            populateCache: false,
            revalidate: false,
            optimisticData: (currentData) => {
                const updated = currentData ? currentData.filter(r=>r.reactionId !== reactionId) : []
                return updated
            }
        }

        backlogReactionStatsApi.decreaseOptimisicCount(backlogId, itemId, value)

        return mutate(getItemReactionsMyFetchKey(backlogId, itemId), updateFn, options)
    }

    return { createReaction, deleteReaction }

}