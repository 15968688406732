import { Slider } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { MouseMoveListener } from "modules/picasso-ui/event/MouseMoveListener"

const diff = (a, b) => a > b ? a - b : b - a;

const PrettoSlider = withStyles(theme => ({
    root: {
      color: theme.palette.primary.main,
      height: 10,
    },
    thumb: {
      height: 30,
      width: 30,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
      '&::after': {
        width: '20px',
        height: '20px',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        backgroundColor: theme.palette.primary.main,
      }
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 10,
      borderRadius: 4,
    },
    rail: {
      height: 5,
      borderRadius: 4,
      color: '#eff2f5',
      opacity: 1,
    },
    mark: {
        height: 5,
        color: 'rgb(199 198 198)',
    },
    markLabel: {
      visibility: 'hidden',
      paddingTop: '8px',
      fontSize: '12px',
/*       [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
        transform: 'rotate(79deg) translateY(30px) translateX(14px)',
        '&[data-index="5"]': {
          visibility: 'hidden',
        },
      } */
      //whiteSpace: 'break-spaces',
/*       ,
      '&[data-index="5"]': {
        paddingTop: '18px',
      },
      '&[data-index="0"]': {
        transform: 'translateX(24px)',
      },
      '&[data-index="10"]': {
        transform: 'translateX(-24px)',
      } */
    }
  }))(props => <Slider {...props} />);

export const SliderWithMouseInput = ({sliderProps, onMouseMoveChange, onClick, paddingTop, paddingBottom}) => {

    const { min, max, value, marks } = sliderProps;
    const intervalLength = diff(min, max);

    const containsZero = marks.some(m=>m.value===0)

    const getClosesStep = (relativePosition) => {
        const step = 10
        const unitsFromLeft = (relativePosition * intervalLength);
        let mod = unitsFromLeft%step;
        if (mod >= step / 2) {
            mod =  -1 * (step - mod);
        }

        const closestStepValueFromLeft = Math.round(unitsFromLeft - mod);

        const closestStep = min + closestStepValueFromLeft

        if (closestStep === 0 && containsZero === false) {
          if (mod > 0) {
            return step;
          } else {
            return -step;
          }
        }

        return closestStep;
    }

    const handleMousePositionChanged = (position) => {
        //console.debug(position);
        const closestStep = getClosesStep(position.relativeX);
        if (closestStep != value) {
            onMouseMoveChange(closestStep);
        }
        //console.debug(closestStep);
    }

    paddingTop = paddingTop || '55px'
    paddingBottom = paddingBottom || '40px'

    return (
        <MouseMoveListener onPositionChanged={handleMousePositionChanged}>
            <div
                onClick={(e)=>{
                  //console.debug('click', e);  
                  onClick(e)
                }}
                onTouchEnd={(e)=>{
                  //console.log('touchend');
                  e.preventDefault()
                  onClick(e)
                }}
                style={{
                    cursor: 'pointer',
                    paddingTop,
                    paddingBottom,
                }}>
                <PrettoSlider
                    {...sliderProps}
                />
            </div>
        </MouseMoveListener>
    )

}