import { IconButton } from "@mui/material"
import { FC, Fragment } from "react"
import { css } from "@emotion/react";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useContainerQuery } from "react-container-query";

export interface RateScaleProps {
    answerEqualAllowed?: boolean
    onButtonHover: (choice: { value:number, side:'left'|'right'|'center'})=>void
    onSubmit: (choice: { value:number, side:'left'|'right'|'center'})=>void
    disabled?: boolean
    onButtonsLeave?: () => void
    /**
     * Rate scale uses JS container query to render the scale responsive.
     * Passing an initial width avoids glitches when the size is of the container not determined yet.
     */
    containerQueryInitialWidth?: number
}

const marks = [
    {
        value: -50,
        label: 'Extremely',
    },
    {
        value: -40,
        label: 'Very strongly',
    },
    {
        value: -30,
        label: 'Strongly',
    },
    {
        value: -20,
        label: 'Moderately',
    },
    {
        value: -10,
        label: 'Slightly',
    },
    {
        value: 0,
        label: 'Equal',
    },
    {
        value: 10,
        label: 'Slightly',
    },
    {
        value: 20,
        label: 'Moderately',
    },
    {
        value: 30,
        label: 'Strongly',
    },
    {
        value: 40,
        label: 'Very strongly',
    },
    {
        value: 50,
        label: 'Extremely',
    },
]

const styleDef = {
    root: css({
        '& .markLabelBelowButton, .markLabelBelowButtonBar': {
            display: 'none',
        },
    }),
    areaBox: theme=>css({
        position: 'relative',
        padding: '4px',
        //border: '1px solid #eaeaea',
        flexBasis: '50%',
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily,
        borderRadius: '4px',
        fontWeight: '500',
        '&::before': {
            position: 'absolute',
            left: 0,
            top: '50%',
            content: '""',
            width: '100%',
            height: '1px',
            backgroundColor: '#eaeaea',
            zIndex: 1
        },
        '& .areaBoxText': {
            backgroundColor: '#fff',
            display: 'inline-block',
            position: 'relative',
            padding: '8px',
            zIndex: 2,
            //textTransform: 'uppercase',
            fontSize: '15px',
            fontWeight: 600,
            color: '#55606b',
        },
    }),
    mark: theme=>({
        borderRadius: '0',
        textAlign: 'center', 
        //'&+&': { paddingLeft: '12px', paddingRight: '12px'}, '&+:last-child': { paddingRight: '0px'}, '&:first-of-type': { paddingRight: '12px'},
        flex: '1 1 0px',
        '&+&': {
            borderLeft: '1px solid #eaeaea',
        },
        padding: '12px 8px',
        display: 'block',
        '& .markIcon': {
            //color: 'rgb(146 159 171 / 54%)',
            color: 'rgb(56 70 83 / 95%)',
        },
        '&:hover .markIcon': {
            display: 'none',
        },
        '& .markIconWhenHover': {
            display: 'none',
            margin: 'auto',
        },
        '&:hover .markIconWhenHover': {
            display: 'block',
            color: 'primary.main'
        },
        height: '78px',
        '&:active': {
            transform: 'translateY(2px)',
        }
    }),
    markDisabled: {
        cursor: 'default !important',
    },
    button: { 
        //borderRadius: '99px', 
        //boxShadow: 'none', 
        margin: 'auto',
        color: 'primary.main',
        //boxShadow: 'inset 0 0 0 1px hsla(0,0%,100%,.15), 0 2px 8px rgba(13,18,22,.15)', 
       border: 'none',
        '&:hover': {
            boxShadow: 'inset 0 0 0 1px hsla(0,0%,100%,.15), 0 2px 8px rgba(13,18,22,.15)', 
        }
    },
    markLabel: theme=>css({ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', textAlign: 'center', marginTop: '18px', fontSize: '12px', whiteSpace: 'nowrap', fontWeight: '600', height: '26px', fontFamily: theme.typography.fontFamily, color: 'rgb(56 70 83 / 95%)'}),
}

const containerMeasureQuery = {
    'downMd': {
        maxWidth: 900,
    },
    'size1': {
        maxWidth: 900,
    },
    'size2': {
        maxWidth: 800,
    },
    'size3': {
        maxWidth: 650,
    },
    'size4': {
        maxWidth: 600,
    },
    'size5': {
        maxWidth: 500,
    },
}


const responsiveRootStyleDef = {
    'downMd': {
        maxWidth: 'calc(100vw - 40px)',
        position: 'relative',
        height: '120px',

        '& .rootResponsive': {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%) scale(0.90)',
            width: '120%',
            top: '0px',
            height: '120px',
        }

    },
    'size1': {
        '& .rootResponsive': {
            transform: 'translateX(-50%) scale(0.80)',
            width: '140%',
        },
        '& .areaBoxText': {
            fontSize: '20px  !important'
        },
    },
    'size2': {
        '& .rootResponsive': {
            transform: 'translateX(-50%) scale(0.75)',
            width: '140%',
        },
        '& .areaBoxText': {
            fontSize: '20px  !important'
        },
    },
    'size3': {
        '& .rootResponsive': {
         /**
          * This is the size that will also be active in rankings preview on most screens (as of the current layout of preview screen)
          * Ideally it should look proportional.
          */
            transform: 'translateX(-50%) scale(0.51)',
            width: '200%',
        },
        '& .markLabelOnButton': {
            fontSize: '17px',
        },
        //paddingBottom: '20px',
    },
    'size4': {
        '& .rootResponsive': {
            transform: 'translateX(-50%) scale(0.50)',
            width: '220%',
        },
        '& .markLabelOnButton': {
            display: 'none',
        },
        '& .markLabelBelowButton': {
            display: 'block',
        },
        '& .markLabelBelowButtonBar': {
            display: 'flex',
        },
        minHeight: '300px',
    },
    'size5': {
        '& .areaBoxText': {
            fontSize: '24px !important'
        },
    },
}

export const RateScale: FC<RateScaleProps> = ({answerEqualAllowed, onButtonHover, onSubmit, disabled, onButtonsLeave, containerQueryInitialWidth}) => {

    // on initial width: 650, the scale does not 'jump' from wide to smaller in RankingPreviewView
    const [containerSize, containerRef] = useContainerQuery(containerMeasureQuery, { width: containerQueryInitialWidth??650 }) 
    
    // Apply responsive styles
    let responsiveRootStyles = []
    if (containerSize.downMd) {
        for (const [key, value] of Object.entries(containerSize)) {
            if (value === true) {
                responsiveRootStyles.push(responsiveRootStyleDef[key])
            }
        }
    }

    const getConvertedValue = (value) => {
        const converted = { } as { value:number, side:'left'|'right'|'center'}
        if (value == 0) {
            converted.value = 1;
            converted.side = 'center';
        } else if (value < 0) {
            converted.value = (-1*(value/10))+1;
            converted.side = 'left';
        } else {
            converted.value = (value/10)+1;
            converted.side = 'right';
        }
        return converted;
    }

    const handleMouseOver = (newValue) => {
        onButtonHover?.(getConvertedValue(newValue));
    }

    const handleSubmit = (newValue) => {
        onSubmit?.(getConvertedValue(newValue))
    }

    return (
        <div css={[styleDef.root, ...responsiveRootStyles]} ref={containerRef}>
            <div className="rootResponsive">
                <div css={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '8px' }}>
                    <div css={styleDef.areaBox} style={{ marginRight: '45px'}}>
                        <div className="areaBoxText">LEFT preferred</div>
                    </div>
                    <div css={styleDef.areaBox} style={{ marginLeft: '45px'}}>
                        <div className="areaBoxText">RIGHT preferred</div>
                    </div>
                </div>
                <div 
                    onMouseLeave={()=>onButtonsLeave?.()}
                    css={{display: 'flex', justifyContent: 'space-evenly', 
                    //border: '1px solid #eaeaea', 
                    borderRadius: '6px', overflow: 'hidden', boxShadow: '0 5px 23px -9px rgb(0 0 0 / 26%)' }}>
                    {marks.map((m)=>{
                        if (m.value === 0 && answerEqualAllowed !== true) {
                            return <Fragment key={m.value}><div style={{width: '40px', borderLeft: '1px solid #eaeaea', borderRight: '1px solid #eaeaea', }}></div></Fragment>
                        }
                        
                        return <IconButton key={m.value} sx={[styleDef.mark, disabled?styleDef.markDisabled:null]} onMouseOver={disabled?null:()=>handleMouseOver(m.value)} onClick={disabled?null:()=>handleSubmit(m.value)} disabled={disabled}>
                                <div css={{height: '15px'}}>
                                    <RadioButtonUncheckedIcon className="markIcon" />
                                    <RadioButtonCheckedIcon className="markIcon markIconWhenHover" />
                                </div>
                                <div className="markLabelOnButton" css={styleDef.markLabel}>{m.label}</div>
                            </IconButton>
                    })}
                </div>
                <div className="markLabelBelowButtonBar" css={{display: 'flex',  justifyContent: 'space-evenly'}}>
                    {marks.map((m)=>{
                        if (m.value === 0 && answerEqualAllowed !== true) {
                            return <Fragment key={m.value}><div style={{width: '40px' }}></div></Fragment>
                        }
                        return <div key={m.value} css={{height: '160px', flex: 1}}><div className="markLabelBelowButton" css={[styleDef.markLabel]} style={{ transform: 'rotate(-75deg) scale(1.7) translateX(-40px) translateY(0px)', transformOrigin: 'top center' }}>{m.label}</div></div>
                    })}
                </div>
            </div>
        </div>
    )

}