import { getReactionsMy } from "modules/plinzip/api/backlogItemsApi"
import useSWR from "swr"
import { notNullNotUndefined } from "utils/objectUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"

export const getItemReactionsMyFetchKey = (backlogId: string, itemId: string):string => {
    notNullNotUndefined(backlogId)
    notNullNotUndefined(itemId)
    return `/rankings/${backlogId}/items/${itemId}/reactions/my`
}

export const useItemReactionsMy = (backlogId: string, itemId: string) => {

    const { data: reactions } = useSWR(backlogId && itemId ? getItemReactionsMyFetchKey(backlogId, itemId) : null, ()=>getReactionsMy(backlogId, itemId).then(handleResponseDataGetContent))

    return { reactions }
    
}