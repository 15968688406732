import React, { FunctionComponent, ReactNode } from "react"
import { createPortal } from "react-dom"

export interface PortalProps {
    children?: ReactNode
    className?: string
    element?: string
}

export const Portal: FunctionComponent<PortalProps> = ({
    children,
    className = 'root-portal',
    element = 'div',
  }) => {
    const [container] = React.useState(() => {
      const el = document.createElement(element)
      el.classList.add(className)
      return el
    })
  
    React.useEffect(() => {
      document.body.appendChild(container)
      return () => {
        document.body.removeChild(container)
      }
    }, [])
  
    return createPortal(children, container)
}