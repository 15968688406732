import { ChangeAction, ChangeEvent, ReactionsBar } from "modules/picasso-ui/form/social/ReactionsBar"
import { FunctionComponent } from "react"
import { RateableItemActionMenu } from "../../rankings/answering/RankingAnsweringComponents"
import { useItemReactionsMy } from "./useItemReactions"
import { useItemReactionsApi } from "./useItemReactionsApi"
import { useItemReactionsStats } from "./useItemReactionsStats"

export interface ItemReactionsBarProps {
    backlogId: string
    itemId: string
    showCounts?: boolean
    showMoreMenu?: boolean
}

export const ItemReactionsBar: FunctionComponent<ItemReactionsBarProps> = ({backlogId, itemId, showCounts, showMoreMenu}) => {

    const { reactions } = useItemReactionsMy(backlogId, itemId)

    const { counts } = useItemReactionsStats(showCounts ? backlogId:null, showCounts?itemId:null)

    const { createReaction, deleteReaction } = useItemReactionsApi()

    const handleChange = (e: ChangeEvent) => {
        if (e.action === ChangeAction.select) {
            createReaction(backlogId, itemId, e.value)
        } else if (e.action === ChangeAction.unselect) {
            const reaction = reactions.find(r=>r.value === e.value)
            if (reaction) {
                deleteReaction(backlogId, itemId, reaction.reactionId, e.value)
            }
        }
    }

    return (
        <ReactionsBar 
            style={()=>({
                maxWidth: '40vw',
                width: 'min-content',
                background: 'rgb(255 255 255 / 18%)',
                padding: '0px 10px',
            })}
            selected={reactions?.map(r=>r.value)||[]}
            counts={counts}
            onChange={handleChange}
            buttonProps={{size: 'small', sx: { fontSize: '28px', paddingTop: '2px', paddingBottom: '2px',}}}
            endContent={showMoreMenu ? <RateableItemActionMenu itemId={itemId} toggleProps={{sx: {'&:hover': {backgroundColor: 'rgba(0,0,0,0.04)'}}}}/> : null}
        />
    )
}