import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { ErrorBoundary } from 'components/error/ErrorBoundary';
import alerts from 'modules/yoio/state/alerts';
import { RaterSlider } from './RaterSlider';
import { CardNice3WithTitleAndDescription } from 'modules/picasso-ui/present/CardNice3WithTitleAndDescription';
import { BlokkText } from 'modules/picasso-ui/present/BlokkText';
import { PortalAppBar } from 'modules/plinzip/views/portal/FeedbackPortalView';
import { useRouter } from 'next/router';
import { CardFloating } from 'modules/picasso-ui/present/card/CardFloating';
import { useTranslation } from 'next-i18next';
import { FormattedText } from 'modules/picasso-ui/present/text/FormattedText';
import { ElementLink } from 'modules/picasso-ui/link/LinkNormal';
import { VerticalAlignBox } from 'modules/picasso-ui/layout/VerticalAlignBox';
import { ChoiceCardsAlignment, RateInput } from 'modules/plinzip/views/backlog/rating/RankingTypes';
import { isHavingValue } from 'utils/objectUtils';
import { notify } from 'modules/yoio/errorsService';
import { AssetsView } from 'modules/picasso-ui/form/assets/AssetsView';
import { OptionDesign } from 'modules/plinzip/views/rankings/answering/RankingAnsweringTypes';
import { useRankingAnsweringTheme } from 'modules/plinzip/views/rankings/answering/useRankingAnsweringTheme';
import { ItemReactionsBar } from 'modules/plinzip/views/items/reactions/ItemReactionsBar';
import { useBacklogIdContext } from 'modules/plinzip/views/rankings/BacklogContext';
import { useRaterSection } from './useRaterSection';
import { RateScale } from './RateScale';
import { InfoTooltipWrapper } from 'components/present/InfoTooltip';
import { useTheme } from '@mui/styles';

const styles = {
    rateableItemContainer: ()=>({
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        flex: 1,
    }),
    optionSecondary: theme=>({
        color: theme.palette.text.secondary
    })
}

export const Rater = ({pair, onItemRated, entryAnimation, renderCard, renderSelectButton, hideSlider, helperTextEnabled, itemsPropsSettings, rateInput, optionDesign, answerEqualAllowed, linkText, choiceCardsAlignment, style, reactionsEnabled, imagesLargeDuringRating}) => {

    const { t } = useTranslation('vsprio')

    const isMounted = useRef(false);

    const [hasSelected, setHasSeleted] = useState(null)
    const [currentTouching, setCurrentTouching] = useState();

    const router = useRouter()

    const theme = useTheme()

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    const handleItemClicked = (item, value, equal) => {
        //console.debug(item, value);
       
        if (equal) {
            setHasSeleted('equal');
        } else {
            setHasSeleted(item.itemId);
        }
        
        setTimeout(()=>{

            if (onItemRated) {
                onItemRated(item, pair, value).then(()=> {

                    if (!isMounted.current) {
                        return;
                    }
                })
                .catch((e)=> {
                    setHasSeleted(null);
                    if (!e || !e.upgradeProposal) {
                        //if upgradeProposal then do nothing, because global handler will show error message
                        if (!e.notified) {
                            notify(e)
                        }
                        if (e?.type !== 'access') {
                            alerts.setMessage({
                                severity: 'error',
                                text: 'We are very sorry. An unexpected error occured. Please try again in a few seconds.',
                            });
                        }
                    }
                });
            }
        }, 300)
    }

    const handleLeftBetterClicked = (value) => {
        handleItemClicked(pair.left, value);
    }

    const handleRightBetterClicked = (value) => {
        handleItemClicked(pair.right, value);
    }

    const handleBothBadClick = () => {
        handleItemClicked(pair.right, 1, true);
    }

    const handleSliderChange = (value) => {
        setCurrentTouching(value.side);
    }

    const handleScaleSubmit = (value) => {
       if (value.side === 'left') {
            handleItemClicked(pair.left, value.value)
       } 
       else if (value.side === 'right') {
            handleItemClicked(pair.right, value.value)
       }
       else if (value.side === 'center') {
        // 1 looks wrong and is not optimal protocol, but is correct value for equal.
            handleItemClicked(pair.right, 1, true);
       } else {
            notify(new Error('unreachable state value (tool will look like freezed or not working to user). value:' + value?.value + ' value.side ' + value?.side ))
       }
    } 


    const cardVariant = Math.max(pair.left.name.length, pair.right.name.length) < 90 ? 'large' : null
        
    const rateInputEffective = router.query?.rateInput || rateInput || RateInput.slider
    
    const showLeftAsSelected = (pair.left.itemId === hasSelected || hasSelected === 'equal')
    const showRightAsSelected = (pair.right.itemId === hasSelected || hasSelected === 'equal')

    return (
        <ErrorBoundary>
            <Box display="flex" justifyContent="space-between" gap="32px" sx={style}>
                <Box sx={styles.rateableItemContainer} justifyContent={choiceCardsAlignment === ChoiceCardsAlignment.center ? 'flex-end':'center'}>
                    <RatableItem 
                        key={pair.left.itemId + pair.pairIdTemp + '_' + pair.left.itemId === hasSelected} 
                        item={pair.left} 
                        onClick={handleItemClicked}
                        fadeOut={isHavingValue(hasSelected)}
                        //elevateHigh={currentTouching==='left' && rateInputEffective !== RateInput.scale}
                        entryAnimation={entryAnimation}
                        renderCard={renderCard}
                        renderSelectButton={renderSelectButton}
                        cardVariant={cardVariant}
                        itemsPropsSettings={itemsPropsSettings}
                        rateInput={rateInputEffective}
                        optionDesign={optionDesign}
                        linkText={linkText}
                        isSelected={showLeftAsSelected}
                        isProposed={(rateInputEffective === RateInput.scale||rateInputEffective === RateInput.slider) && currentTouching === 'left'}
                        style={{
                            alignItems: choiceCardsAlignment === ChoiceCardsAlignment.center ? 'flex-end':'center'
                        }}
                        //width={choiceCardsAlignment === ChoiceCardsAlignment.center ? '360px':null}
                        //maxWidth={choiceCardsAlignment === ChoiceCardsAlignment.center ? '100%':null}
                        reactionsEnabled={reactionsEnabled}
                        />
                </Box>
                <Box sx={styles.rateableItemContainer} justifyContent={choiceCardsAlignment === ChoiceCardsAlignment.center ? 'flex-start':'center'} data-test="test1234">
                    <RatableItem 
                        key={pair.right.itemId + pair.pairIdTemp + '_' + pair.right.itemId === hasSelected} 
                        item={pair.right} 
                        onClick={handleItemClicked}
                        fadeOut={isHavingValue(hasSelected)}
                        //elevateHigh={currentTouching==='right' && rateInputEffective !== RateInput.scale}
                        entryAnimation={entryAnimation}
                        renderCard={renderCard}
                        renderSelectButton={renderSelectButton}
                        cardVariant={cardVariant}
                        itemsPropsSettings={itemsPropsSettings}
                        rateInput={rateInputEffective}
                        optionDesign={optionDesign}
                        linkText={linkText}
                        isSelected={showRightAsSelected}
                        isProposed={(rateInputEffective === RateInput.scale||rateInputEffective === RateInput.slider) && currentTouching === 'right'}
                        style={{
                            alignItems: choiceCardsAlignment === ChoiceCardsAlignment.center ? 'flex-start':'center'
                        }}
                        //width={choiceCardsAlignment === ChoiceCardsAlignment.center ? '360px':null}
                        //maxWidth={choiceCardsAlignment === ChoiceCardsAlignment.center ? '100%':null}
                        reactionsEnabled={reactionsEnabled}
                        />
                </Box>
            </Box>
            {rateInputEffective === RateInput.slider && hideSlider !== true && 
                <Container maxWidth="sm" sx={{flexGrow: imagesLargeDuringRating ? 0 : 1}}>
                    {helperTextEnabled !== false &&
                        <Box display="flex" mt={6} justifyContent="center">
                            <InfoTooltipWrapper text={t('vsprio:ranking.answering.ratingHelp.rateInput.slider.helperText2')} iconStyle={{opacity: '0.4'}}>
                                <Typography color="text.secondary" fontSize="14px">{t('vsprio:ranking.answering.ratingHelp.rateInput.slider.helperTitle2')}</Typography>
                                </InfoTooltipWrapper>
                        </Box>
                    }
                    <RaterSlider 
                        onLeftBetter={handleLeftBetterClicked}
                        onRightBetter={handleRightBetterClicked}
                        onEqual={handleBothBadClick}
                        onChange={handleSliderChange}
                        answerEqualAllowed={answerEqualAllowed}
                    />
                </Container>
            }
            {/** If you adjust the Conatiner width, also change <RateScale> containerQueryInitialWidth */}
            {rateInputEffective === RateInput.scale && hideSlider !== true && 
                <Container sx={theme=>({flexGrow: imagesLargeDuringRating ? 0 : 1, maxWidth: theme.breakpoints.values.md+150})}>
{/*                     {helperTextEnabled !== false &&
                        <Box color="text.secondary" textAlign="center" mt={6}>Preference:</Box>
                    } */}
                    {/** maxWidth and overflow visible required to make container query in children work */}
                    <Box style={{ textAlign: 'center', maxWidth: 'calc(100vw - 50px)', overflow: 'visible' }} mt={5}>
                        <RateScale 
                            answerEqualAllowed={answerEqualAllowed}
                            onButtonHover={handleSliderChange}
                            onSubmit={handleScaleSubmit}
                            // disabled: checking hasSelected only works because a new rater instance is rendered after each selection
                            disabled={isHavingValue(hasSelected)}
                            onButtonsLeave={()=>setCurrentTouching(null)}
                            // containerQueryInitialWidth based on Container width
                            containerQueryInitialWidth={typeof window !== 'undefined' 
                                ? (window?.innerWidth > theme.breakpoints.values.md+150 ? theme.breakpoints.values.md+150 : (window?.innerWidth??24)-24)
                                : null
                            }
                        />
                    </Box>
                </Container>
            }
            {rateInputEffective === RateInput.buttonsSimple && answerEqualAllowed &&
                <Box textAlign="center" mt={6} flexGrow={imagesLargeDuringRating ? 0 : 1}>
                    <EqualButton  onClick={handleBothBadClick}/>
                </Box>
            }
        </ErrorBoundary>
    );

}

const EqualButton = (props) => {
    const { t } = useTranslation('vsprio')
    const { answeringTheme } = useRankingAnsweringTheme()

    return <Button color="greyDark" variant="outlined" {...props} sx={answeringTheme.choiceEqualButton?.style} size={answeringTheme.choiceEqualButton?.size || props.size} >{t('vsprio:ranking.answering.buttonsSimple.equal.text')}</Button>
}

const RatableItem = ({item, align, onClick, fadeOut, isSelected, isProposed, elevateHigh, entryAnimation, renderCard, renderSelectButton, cardVariant, rateInput, optionDesign, linkText, width, maxWidth, style, reactionsEnabled}) => {

    const { t } = useTranslation('vsprio')

    const backlogIdContext = useBacklogIdContext()
    // backlogId can be null in Rater preview
    const backlogId = backlogIdContext?.backlogId || null

    const reactionsBarContRef = useRef()
    const imageRef = useRef()

    const [imageRefReady, setImageRefReady] = useState(false)

    const { getAvailableSpaceForOneCard } = useRaterSection()

    let resizeObserverRef = useRef()

    /**
     *  User Actions
     */

    const handleValueClicked = (value) => {
        onClick(item, value);
    }

    const handleValueClickedSimple = () => {
        handleValueClicked(2)
    }

    //const [imageRef, { width: imageWidth, height: imageHeight }] = useElementSize()

    /**
     * Correct the position of the Item Reactions Bar. It cannot be centered with pure CSS (because the image is growing some new CSS rules such as min-content)
     * Correct the bar to be positioned in the horizontal center of the image
     */
    const useReactionBarPositionCorrection = optionDesign === OptionDesign.imageCover && reactionsEnabled

/* 
    console.log('imageWidth', imageWidth)
    console.log('imageHeight', imageHeight) */

    /**
     * Functions
     */

    const adjustReactionsBarCont = () => {
         
        if (reactionsBarContRef.current && imageRef.current) {
            // -100 is padding correction. the available space for the reactionsbar is half of the window width minus some paddings. this is not 100% accurate.
            // That property will only be effective if the window is so small, that images will shrink. Can be considered as egde case for now.

            const imageAvailableSpace = getAvailableSpaceForOneCard()

            const targetMaxWidth = imageAvailableSpace && imageAvailableSpace < imageRef.current.offsetWidth ? (imageAvailableSpace + 'px') : (imageRef.current.offsetWidth + 'px')
            const targetWidth = imageRef.current.offsetWidth+'px'

            //console.debug('targetMaxWidth', targetMaxWidth)
            //console.debug('targetWidth', targetWidth)

            reactionsBarContRef.current.style.maxWidth = targetMaxWidth
            reactionsBarContRef.current.style.width = targetWidth
            // Is initially hidden until positioned to avoid style glitches
            reactionsBarContRef.current.style.visibility='inherit'
            reactionsBarContRef.current.style.removeProperty('display')
        }
    }

    /**
     *  Events
     */

     const registerImageResizeObserverIfNecessary = () => {
       if (optionDesign === OptionDesign.imageCover && reactionsEnabled) {
           if (!imageRef.current || resizeObserverRef.current) {
               // Nothing to do
               return;
           }
           resizeObserverRef.current = new ResizeObserver(() => {
                adjustReactionsBarCont()
           });
           resizeObserverRef.current.observe(imageRef.current);
           adjustReactionsBarCont();
       } else {
            cleanupImageResizeObserver()
       }
     }

     const cleanupImageResizeObserver = () => {
        resizeObserverRef.current?.disconnect()
        if (reactionsBarContRef.current) {
            reactionsBarContRef.current.style.removeProperty('width')
            reactionsBarContRef.current.style.removeProperty('maxWidth')
        }
     }

     const handleWindowResize = () => {
        if (optionDesign === OptionDesign.imageCover && reactionsEnabled) {
            adjustReactionsBarCont()
        }
     }

     const onRefChange = useCallback(node => {
        if (node) { 
            imageRef.current = node
            //setImageRefReady(true)
            registerImageResizeObserverIfNecessary()
        } else {
            imageRef.current = null
            if (useReactionBarPositionCorrection) {
                //setImageRefReady(false)               
                reactionsBarContRef.current.style.width='0'
                reactionsBarContRef.current.style.display='none'
                reactionsBarContRef.current.style.removeProperty('width')
                reactionsBarContRef.current.style.removeProperty('maxWidth')
            }
        }
      }, [])

/*     useEffect(()=>{
        if (optionDesign === OptionDesign.imageCover && reactionsEnabled) {

        }


    },[reactionsEnabled, optionDesign]) */

    useEffect(() => {
        registerImageResizeObserverIfNecessary()
        window.addEventListener('resize', handleWindowResize)

        return () => { 
            cleanupImageResizeObserver()
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [reactionsEnabled, optionDesign])

    useEffect(()=>{
        if (isSelected === true && useReactionBarPositionCorrection && reactionsBarContRef.current) {
            reactionsBarContRef.current.style.visibility='hidden'
        }
    }, [isSelected])

    /**
     *  Render
     */

    const renderOptions = (options) => {
        return options.map(o=><Box mb={0.6} key={o.value}><Button 
            sx={o.color === 'primary' ? null : styles.optionSecondary}
            fullWidth={true} 
            color={o.color}
            variant="contained" 
            onClick={()=>handleValueClicked(o.value)}>{o.name}
        </Button></Box>);
    }

    const optionsElems = renderOptions([
        {
            name: "Much better",
            value: 6
        },
        {
            name: "Better",
            value: 4,
            color: "primary"
        },
        {
            name: "Slightly better",
            value: 2
        },
        {
            name: "Equal",
            value: 1
        },
    ]);

    const optionButtonsEnabled = false;

    const cardProps = { 
        ref: useReactionBarPositionCorrection ? onRefChange : null,
        item,
        title: item.name,
        variant: cardVariant,
        elevateHigh,
        label: item.props?.categories?.join(', '),
        //elevateHighOnHover: rateInput === RateInput.buttonsSimple,
        onClick: rateInput === RateInput.buttonsSimple?handleValueClickedSimple:null,
        description: <FormattedText enableLinks>{item.description}</FormattedText>,
        assets: item.assets,
        isSelected,
        isProposed,
        entering: entryAnimation,
        leaving: fadeOut,
        // { transition: 'none' }  because otherwise there is a glitch / css conflict on CardTW because CardTW uses transition 
        sx: { transition: 'none' },
        endContent: ((item.props && item.props?.links)||item.assets) ?
                <VerticalAlignBox spacing={2}>
                    {optionDesign === OptionDesign.textAndAssets && item.assets && <VerticalAlignBox><AssetsView assets={item.assets} viewerAllowed/></VerticalAlignBox>}
                    {item.props?.links && 
                        <>{item.props?.links.map(l=>{
                            return (
                                <ElementLink key={l} href={l} rel="noreferrer" target="_blank">
                                    <Typography 
                                        sx={(theme)=>({
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                textDecorationColor: theme.palette.greyDark.main,
                                            }
                                        })}
                                        component="span" color="greyDark.main" fontSize="0.8em"
                                    >{linkText || 'Link'}</Typography>
                                </ElementLink>
                            )
                        })}</>
                    }
{/*                     {item.props?.times && 
                        <>{item.props?.times.map(t=>{
                            return (
                                <Typography component="span" color="greyDark.main" fontSize="0.8em">{t}</Typography>
                            )
                        })}</>
                    } */}
{/*                     {Object.keys(item.props).filter(p=>p!=='links').map(prop=>{
                        const values = item.props[prop];

                        return (
                            <></>
                            <Box key={prop}>
                                <VerticalAlignBox spacing={1} flexWrap="wrap">
                                    {values.map((v)=><ChipStyled label={v} key={v} />)}
                                </VerticalAlignBox>
                            </Box>
                        )
                    })} */}
                </VerticalAlignBox>
                : null
    }

    renderCard = renderCard || ((props) => {
        return <CardFloating {...props}/>
    })

    const buttonsSimpleEnabled = rateInput === RateInput.buttonsOnePerItem

    renderSelectButton = renderSelectButton || ((props)=>{
        return (<Button {...props} />
        )
    })

    const selectBtnProps = {
        sx:(theme)=>({
            backgroundColor: theme.palette.primary?.main ? `${theme.palette.primary.main} !important`:null, 
            color: '#fff',
        }),
        color: "primary",
        size: "large",
        variant: "contained",
        onClick: ()=>handleValueClicked(3),
        children: t('vsprio:ranking.answering.buttonsSimple.buttonText')
    }

    const alignEffective = align || 'center'
    
    const reactionsContStyle = {
        visibility: isSelected || useReactionBarPositionCorrection ? 'hidden' : null
    }

    return (
        <Box 
            data-hj-suppress 
            display="flex" 
            flexDirection="column" 
            alignItems={alignEffective} 
            height="100%" 
            width={width || (alignEffective === 'center' ? '100%' : null)}
            maxWidth={maxWidth}
            style={style}
        >
            {renderCard(cardProps)}
            {optionButtonsEnabled && (
                <Box display="flex" flexDirection="column" mt={3} style={{width: '160px'}}>
                    {optionsElems}
                </Box>
            )}
            {reactionsEnabled && backlogId && 
                <Box 
                    ref={reactionsBarContRef}
                    sx={theme=>({
                    marginTop: '12px', 
                    [theme.breakpoints.down('md')]: {
                        width: useReactionBarPositionCorrection ? null:'100%',
                        textAlign: 'center',
                        '& > div': {
                            margin: 'auto',
                        }
                    },
                    [theme.breakpoints.up('md')]: {
                        //minWidth: '250px',
/*                         paddingLeft: '36px',
                        paddingRight: '36px', */
                        width: useReactionBarPositionCorrection ? null:'100%',
                        maxWidth: useReactionBarPositionCorrection ? null:'300px',
                        textAlign: 'center',
                        '& > div': {
                            margin: 'auto',
                        }
                    },
                })}
                style={reactionsContStyle}
                >
                    <ItemReactionsBar backlogId={backlogId} itemId={item.itemId} showMoreMenu />
                </Box>
            }
            {buttonsSimpleEnabled &&
                <Box textAlign="center" mt={3}>
                    {renderSelectButton(selectBtnProps)}
                </Box>
            }
        </Box>
    )

}

const raterDemoItem = { 
    "left": {   
        "itemId": "1234",
        "name": "Idea 1", 
        "description": "", //"Als Administrator Nutzer will ich eine Integration mit Asana anlegen können, damit alle Tasks aus einem Projekt als ein Backlog in VSPRIO erscheinen und mein Team Asana Tasks priorisieren kann und der Score der Tasks wiederrum in Asana angezeigt wird.\n\nAuch die Beschreibung soll importiert werden. Nur der Administrator kann die Integration ändern.", 
        "links": [ { "rel": "rateHigher", "href": "http://localhost/" } ], 
        "refItemId": "86ea162b-b8fa-41df-9991-1efb7bbfc764", 
        "isLeftItem": true 
    }, 
    "right": { 
        "itemId": "5678", 
        "name": "Idea 2 ", 
        "description": "", //"Als Administrator Nutzer will ich eine Integration mit Asana anlegen können, damit alle Tasks aus einem Projekt als ein Backlog in VSPRIO erscheinen und mein Team Asana Tasks priorisieren kann und der Score der Tasks wiederrum in Asana angezeigt wird.\n\nAuch die Beschreibung soll importiert werden. Nur der Administrator kann die Integration ändern.", "links": [ { "rel": "rateHigher", "href": "http://localhost/" } ], 
        "refItemId": "780a7082-9bbc-4ee9-9606-2c326931a4b4", 
        "isRightItem": true 
    }, 
    "pairIdStack": 544929, 
    "pairIdTemp": 489252 
}

/**
 * Only for viewing, does not support interaction
 * @returns
 */
export const RaterDemo = ({renderCard, hideSlider, helperTextEnabled, choiceCardsAlignment, pair}) => {

    return (
        <Rater 
            key="xyz"
            pair={pair || raterDemoItem}
            renderCard={renderCard}
            hideSlider={hideSlider}
            helperTextEnabled={helperTextEnabled}
            choiceCardsAlignment={choiceCardsAlignment}
        />
    )

}

export const RaterDemoSimpleStupid = () => {

    return (
        <Box>
            <Box width="50%" display="inline-block" pr={1}>
                <CardNice3WithTitleAndDescription height={'120px'} fullWidth={true} title="Idea 1" description="Lorem" />
            </Box>
            <Box width="50%" display="inline-block" pl={1}>
                <CardNice3WithTitleAndDescription height={'120px'} fullWidth={true} title="Idea 2" description="Lorem" />
            </Box>
        </Box>
    )

}

const raterDemoItemsIteractive = [
    { 
        "left": {   
            "itemId": "1234",
            "name": <span style={{textAlign: 'center'}}>Add filter</span>, 
            "description": <span display="inline-block"style={{textAlign: 'center'}}><BlokkText>Thisasd asdasd</BlokkText></span>, 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "86ea162b-b8fa-41df-9991-1efb7bbfc764", 
            "isLeftItem": true 
        }, 
        "right": { 
            "itemId": "5678", 
            "name": <span style={{textAlign: 'center'}}>Add labels</span>, 
            "description": <span display="inline-block"style={{textAlign: 'center'}}><BlokkText>Thisasd asdasd</BlokkText></span>, 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "780a7082-9bbc-4ee9-9606-2c326931a4b4", 
            "isRightItem": true 
        }, 
        "pairIdStack": 544929, 
        "pairIdTemp": 489252 
    },
    { 
        "left": {   
            "itemId": "1234",
            "name": "Asana Sync", 
            "description": "Tasks should be imported from Asana.", 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "86ea162b-b8fa-41df-9991-1efb7bbfc764", 
            "isLeftItem": true 
        }, 
        "right": { 
            "itemId": "5678", 
            "name": "CSV Export", 
            "description": "I'd like to be able to export tasks as CSV.", 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "780a7082-9bbc-4ee9-9606-2c326931a4b4", 
            "isRightItem": true 
        }, 
        "pairIdStack": 544929, 
        "pairIdTemp": 489252 
    },
    { 
        "left": {   
            "itemId": "1234",
            "name": "Task Labels", 
            "description": "I'd like to be able to add labels.", 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "86ea162b-b8fa-41df-9991-1efb7bbfc764", 
            "isLeftItem": true 
        }, 
        "right": { 
            "itemId": "5678", 
            "name": "Newsletter Redesign", 
            "description": "The newsletter should be structured by features.", 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "780a7082-9bbc-4ee9-9606-2c326931a4b4", 
            "isRightItem": true 
        }, 
        "pairIdStack": 544930, 
        "pairIdTemp": 489253 
    }
]

export const buildSimplePreviewItem = (title1, title2) => {
    return { 
        "left": {   
            "itemId": "1234",
            "name": <Box textAlign="center">{title1}</Box>, 
            "description": <Box textAlign="center"><BlokkText>Thisasd asdasd</BlokkText></Box>, 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "86ea162b-b8fa-41df-9991-1efb7bbfc764", 
            "isLeftItem": true 
        }, 
        "right": { 
            "itemId": "5678", 
            "name": <Box textAlign="center">{title2}</Box>, 
            "description": <Box textAlign="center"><BlokkText>Thisasd asdasd</BlokkText></Box>, 
            "links": [ { "rel": "rateHigher", "href": null } ], 
            "refItemId": "780a7082-9bbc-4ee9-9606-2c326931a4b4", 
            "isRightItem": true 
        }, 
        "pairIdStack": 544929, 
        "pairIdTemp": 489252 
  }
}

export const RaterDemoInteractive = ({hideSlider, question, item}) => {

    const [count, setCount] = useState(0)

    const getNextItem = () => {
       const nextIndex = count % raterDemoItemsIteractive.length
       return raterDemoItemsIteractive[nextIndex]
    }

    return (
        <>
            <Box textAlign="center" mb={5}>
                <Typography variant="h3" style={{color: 'rgb(1 48 101 / 79%)', fontSize:'1rem', fontWeight: '500'}}>{question || 'Which idea is more effective?'}</Typography>
            </Box>
            <Rater 
                hideSlider={hideSlider}
                key={count}
                pair={item || getNextItem()}
                onItemRated={()=>{
                    setCount(c=>c+1); 
                    return Promise.resolve()
                }}
                renderCard={(props)=><CardNice3WithTitleAndDescription {...props} height="120px" />}
            />
        </>
    )

}

export const RaterDemoWithAppbar = ({customName, item}) => {

    return (
        <>
            <PortalAppBar 
                portal={null} 
                appBarPosition={'static'} 
                customName={(customName) || 'Rating'} 
                appBarPushEnabled={false}
                slim={true}
            />

            <Box p={4}>
                <RaterDemoInteractive hideSlider={true} item={item}/>
            </Box>
        </>
    )

}