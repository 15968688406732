import { Box, Tooltip } from "@mui/material";
import { SliderWithMouseInput } from "modules/picasso-ui/form/SliderWithMouseInput";
import { useState } from "react";

function valuetext(value) {
    return `${value}`;
}

function valueLabelFormat(value) {
    const mark = marks.find((mark) => mark.value === value);
    if (!mark) {
        return null;
    }
    return mark.label;
  }

const marks = [
    {
        value: -50,
        label: 'Extremely more',
    },
    {
        value: -40,
        label: '',
    },
    {
        value: -30,
        label: 'Quite more',
    },
    {
        value: -20,
        label: '',
    },
    {
        value: -10,
        label: 'Slightly more',
    },
    {
        value: 0,
        label: 'Equal',
    },
    {
        value: 10,
        label: 'Slightly more',
    },
    {
        value: 20,
        label: '',
    },
    {
        value: 30,
        label: 'Quite more',
    },
    {
        value: 40,
        label: '',
    },
    {
        value: 50,
        label: 'Extremely more',
    },
];

export const RaterSlider = ({onLeftBetter, onRightBetter, onEqual, onChange, answerEqualAllowed}) => {

    const [value, setValue] = useState(0);

    const getConvertedValue = (value) => {
        const converted = {};
        if (value == 0) {
            converted.value = 1;
            converted.side = 'center';
        } else if (value < 0) {
            converted.value = (-1*(value/10))+1;
            converted.side = 'left';
        } else {
            converted.value = (value/10)+1;
            converted.side = 'right';
        }
        return converted;
    }

    const handleMouseMoveChange = (newValue) => {
        if (isNaN(newValue)) {
            return;
        }
        setValue(newValue);
        if (onChange) {
            onChange(getConvertedValue(newValue));
        }
    }

    const handleClick = () => {
        const converted = getConvertedValue(value);

        // 1 is equal
        if (converted.value === 1) {
            onEqual()
        } else if (converted.side === 'left') {
            onLeftBetter(converted.value);
        } else {
            onRightBetter(converted.value);
        }
    }

    const paddingTop = '15px'
    const paddingBottom = '0px'

    const marksEffective = answerEqualAllowed ? marks : marks.filter(m=>m.value !== 0)

    return (
        <Box>
            <SliderWithMouseInput
                onClick={handleClick}
                onMouseMoveChange={handleMouseMoveChange}
                sliderProps={{
                    components:{
                        ValueLabel: ValueLabelComponent,
                    },
                    value,
                    valueLabelFormat,
                    getAriaValueText: valuetext,
                    'aria-labelledby': "discrete-slider",
                    valueLabelDisplay: "auto",
                    step: null,
                    marks: marksEffective,
                    min: -50,
                    max: 50,
                    track: false,
                    onChange:(e, newValue)=>setValue(newValue)
                }}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
            />
        </Box>
    )

}

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    if (!value) {
        return <>{children}</>
    }
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }