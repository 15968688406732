import React from "react";
import { round } from "utils/numberUtils";

export const MouseMoveListener = ({children, onPositionChanged}) => {

    const inputRef = React.createRef();

    const handleMouseMove = (e) => {
        
        var rect = inputRef.current.getBoundingClientRect();

        const position = { x: e.clientX - rect.left, y: e.clientY - rect.top };
        //const position = { x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY }; //old

        const elemWidth = inputRef.current.offsetWidth;
        const elemHeight = inputRef.current.offsetHeight;

        if (position.x < 0) {
            position.x = 0;
        } else if (position.x > elemWidth){
            position.x = elemWidth;
        }
        if (position.y < 0) {
            position.y = 0;
        } else if (position.y > elemHeight){
            position.y = elemHeight;
        }
        position.relativeX = Number(round(position.x / elemWidth, 2));
        position.relativeY = Number(round(position.y / elemHeight, 2));
        
        onPositionChanged(position);
    }

    const child = React.Children.only(children);

    const clone = React.cloneElement(child, {onMouseMove: handleMouseMove, ref: inputRef});

    return (
        <>
            {clone}
        </>
    );

}