import { getReactionsStats } from "modules/plinzip/api/backlogApi"
import useSWR, { useSWRConfig } from "swr"
import { structuredCloneOrFallback } from "utils/objectUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"
import { BacklogReactionsStats } from "../../backlog/rating/RankingTypes"

export const getBacklogReactionStatsFetchKey = (backlogId) => {
    return `/backlogs/${backlogId}/reactionsStats`
}

export interface BacklogReactionStatsApiProps {
    increaseOptimisicCount: (backlogId:string, itemId:string, value:string)=>Promise<any>
    decreaseOptimisicCount: (backlogId:string, itemId:string, value:string)=>Promise<any>
}

export const useBacklogReactionStatsApi = ():BacklogReactionStatsApiProps => {

    const { mutate } = useSWRConfig()

    const increaseOptimisicCount = (backlogId:string, itemId:string, value: string) => {
            // Increase stats by 1
            const options = {
                populateCache: false,
                revalidate: false,
                optimisticData: (currentData:BacklogReactionsStats) => {
                    if (!currentData) {
                        return null
                    }
                    const updated = structuredCloneOrFallback(currentData)
                    const counts = updated.itemsReactionsStats?.[itemId]?.counts
                    counts['total'] = (counts?.['total']||0)+1
                    counts[value] = (counts?.[value]||0)+1
                    return updated
                }
            }

            return mutate(getBacklogReactionStatsFetchKey(backlogId), ()=>{}, options)
    }

    const decreaseOptimisicCount = (backlogId:string, itemId:string, value: string) => {
        // Decrease stats by 1
        const options = {
            populateCache: false,
            revalidate: false,
            optimisticData: (currentData:BacklogReactionsStats) => {
                if (!currentData) {
                    return null
                }
                const updated = structuredCloneOrFallback(currentData)
                const counts = updated.itemsReactionsStats?.[itemId]?.counts
                if (counts?.['total']) {
                    counts['total'] = Math.max(0,counts?.['total']-1)
                }
                if (counts?.[value]) {
                    counts[value] = Math.max(0,counts?.[value]-1)
                }
                return updated
            }
        }

        return mutate(getBacklogReactionStatsFetchKey(backlogId), ()=>{}, options)
    }

    return {increaseOptimisicCount, decreaseOptimisicCount}
}

export const useBacklogReactionStats = (backlogId: string):BacklogReactionsStats => {

    const { data } = useSWR<BacklogReactionsStats>(backlogId?getBacklogReactionStatsFetchKey(backlogId):null, ()=>getReactionsStats(backlogId).then(handleResponseDataGetContent), {
        focusThrottleInterval: 60000
    })
    return data
}