export const RATER_SECTION_CLASSNAME = 'raterSectionElem'

export interface UseRaterElementApi {
    getWidth: () => number
    getAvailableSpaceForOneCard: () => number
}

export const useRaterSection = () => {

    const getWidth = () => {
        if (typeof document === 'undefined') {
            return null
        }
        const elements = document.getElementsByClassName(RATER_SECTION_CLASSNAME)
        if (elements.length < 1) {
            return null
        }
        return elements[0].clientWidth
    }

    const getAvailableSpaceForOneCard = () => {
        const width = getWidth()
        if (!width) {
            return null
        }
        //32 px gap
        return ((width-32)/2)
    }

    return { getWidth, getAvailableSpaceForOneCard }
}