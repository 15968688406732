import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { joinClasses } from "utils/muiUtils";
import { isHavingValue, orElse } from "utils/objectUtils";

const cardHeight = '280px';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        '&:hover $description, &:hover $title': {
            overflow: 'visible',
            lineClamp: 1000 //intention: show everything. approach 'none' did not work
        },
        '&:hover $root': {
            height: 'inherit',
            zIndex: '100'
        },
        position: 'relative',
    },
    wrapperMinHeightEnabled: {
        minHeight: cardHeight,
    },
    root: {
        borderTop: `3px solid ${theme.palette.primary.main}`,
        padding: '17px',
        borderRadius: '8px',
        backgroundColor: '#fff',

        boxShadow: '0 1.5em 2.5em -0.5em rgb(0 0 0 / 10%)',

        width: '100%',
        [theme.breakpoints.down('md')]: {
            //transform: 'scale(0.7)'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            transform: 'scale(1)',
        },
        transition: '0.3s',
    },
    rootLarge: {
        padding: '30px',
        '& $title': {
            fontSize: '20px',
        }
    },
    rootMinHeightEnabled: {
        height: cardHeight,
        minHeight: cardHeight,
    },
    elevateHigh: {
        //boxShadow: '0px 15px 40px 0 rgb(58 65 93 / 22%)',
        //boxShadow: '0px 8px 25px 0 rgb(58 65 93 / 22%)',
        //border: '1px solid ' + theme.palette.primary.light,
        //backgroundColor: '#d3e8fd',
        transform: 'translateY(-6px)'
    },
    withMaxWidth: {
        maxWidth: '350px',
    },
    clickable: {
        '&:hover': {
            backgroundColor: '#fff',
            boxShadow: '0 0 12px rgba(31,31,36,.16), 0 4px 48px rgba(31,31,36,.08)'
        },
    },
    content: {
        height: '100%',
        //overflow: 'hidden',
    },
    icon: {
        fontSize: '30px'
    },
    title: {
        minHeight: '38px',
        display: 'box',
        //lineClamp: 3,
        boxOrient: 'vertical',  
        overflow: 'hidden',
        fontWeight: '600',
        fontSize: '16px',
        color: '#213053', //theme.palette.text.primary, //'rgb(40 43 69)',
        //fontSize: '30px',
        [theme.breakpoints.down('sm')]: {
            //fontSize: '18px',
        },
    },
    descriptionCont: {

    },
    description: {
        display: 'box',
        //: 3,
        boxOrient: 'vertical',  
        overflow: 'hidden',
        color: theme.palette.text.secondary,
        fontWeight: '400',
    }
  }));

/**
 * 
 * @param {*} variant 
 *      medium, large
 * @returns 
 */
export const CardFloating = ({title, description, onClick, className, fullWidth, elevateHigh, height, variant, endContent}) => {

    const classes = useStyles();

    const minHeightEnabled = isHavingValue(height) ? false : true

    const classNamesWrapper = [classes.wrapper];

    variant = orElse(variant, 'medium')

    const classNamesRoot = [classes.root];
    if (className) {
        classNamesRoot.push(className);
    }

    if (fullWidth !== true) {
        classNamesRoot.push(classes.withMaxWidth);
    }
    if (onClick) {
        classNamesRoot.push(classes.clickable);
    }
    if (elevateHigh === true) {
        classNamesRoot.push(classes.elevateHigh);
    }
    if (minHeightEnabled) {
        classNamesRoot.push(classes.rootMinHeightEnabled);
        classNamesWrapper.push(classes.wrapperMinHeightEnabled);
    }
    if (variant === 'large') {
        classNamesRoot.push(classes.rootLarge);
    }


    const wrapperStyle = {}

    if (height) {
        wrapperStyle.height = height
    }

    const rootStyle = {}

    if (height) {
        rootStyle.height = height
    }

    return (
        <div className={joinClasses(classNamesWrapper)} style={wrapperStyle}>
            <Box className={classNamesRoot.join(' ')} align="left" onClick={onClick} position="absolute" style={rootStyle}>
                <Box className={classes.content} display="flex" flexDirection="column">
                    <Box>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Box>
                    <Box className={classes.descriptionCont} mt={1} flexGrow="1">
                        <Typography className={classes.description}>
                            {description}
                        </Typography>
                    </Box>
                    {endContent}
                </Box>
            </Box>
        </div>
    );

}